module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../packages/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://ghost.mycmmc.com","contentApiKey":"3752ec79574dbcdb50e7b57827"},"production":{"apiUrl":"https://ghost.mycmmc.com","contentApiKey":"3752ec79574dbcdb50e7b57827"}},"siteConfig":{"siteUrl":"https://mycmmc.com","postsPerPage":12,"siteTitleMeta":"myCMMC","siteDescriptionMeta":"","shareImageWidth":1000,"shareImageHeight":523,"shortTitle":"myCMMC","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A"}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-toc/gatsby-browser.js'),
      options: {"plugins":[],"maxDepth":2},
    }]
